import React, { useState } from 'react';
import { Route as ReactRoute, Redirect as ReactRedirect } from 'react-router-dom';

import useSWR from 'swr';
import axiosInstance from 'utils/interceptors';

const Route = ({ component: Component, ...rest }) => {  
  const [time] = useState(Date.now());

  const fetcher = url => axiosInstance.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`}}).then(res => res.data);
  const { data: auth, isLoading: loadingAuth } = useSWR(`${process.env.REACT_APP_CLOUD_CONNECT_API}/me?_=${time}`, fetcher);

  const handlePermission = props => {
    const { isPublic } = rest;

    if (isPublic) {
      return <Component {...props} />;
    }

    if (!loadingAuth) {
      return auth
        ? <Component {...props} />
        : <ReactRedirect to={{ pathname: '/forbidden' }} />;
    }
  };

  return <ReactRoute {...rest} render={props => handlePermission(props)} />;
};

export default Route;