import { lazy } from 'react';

const Redirect = lazy(() => import('pages/redirect'));
const Forbidden = lazy(() => import('pages/forbidden'));
const DeviceData = lazy(() => import('pages/device'));
const DeviceSettings = lazy(() => import('pages/settings'));
const Systems = lazy(() => import('pages/systems'))
const Connectivity = lazy(() => import('pages/reports/connectivity'))
const CalibrationsReport = lazy(() => import('pages/reports/calibrations'))
const UploadCalibrationCSV = lazy(() => import('pages/reports/calibrations/upload'));

export const routes = [{
  path: '/redirect',
  component: Redirect,
  isPublic: true
}, {
  path: '/forbidden',
  component: Forbidden,
  isPublic: true
}, {
  path: '/device/data/:id',
  component: DeviceData,
  isPublic: false
}, {
  path: '/device/settings/:id',
  component: DeviceSettings,
  isPublic: false
}, {
  path: '/systems',
  component: Systems,
  isPublic: false
},
{
  path: '/reports/connectivity',
  component: Connectivity,
  isPublic: false
},
{
  path: '/reports/calibrations',
  component: CalibrationsReport,
  isPublic: false
},
{
  path: '/reports/calibrations/upload',
  component: UploadCalibrationCSV,
  isPublic: false
}
];
