import axios from 'axios';

const axiosInstance = axios.create();
let refreshTimer;

const refreshToken = async () => {
  const result = await axiosInstance.post(`${process.env.REACT_APP_CLOUD_CONNECT_API}/refreshToken`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`}});

  localStorage.setItem('JWT_TOKEN', result.data.access_token);

  setupRefreshTimer(result.data.expires_in);
}

const setupRefreshTimer = (refreshTime) => {
  cancelRefreshTimer();

  const timeout = refreshTime < 20 ? 20 : refreshTime - 20;

  refreshTimer = setTimeout(async () => {
    await refreshToken();
  }, (timeout) * 1000);
}

const cancelRefreshTimer = () => {
  if (refreshTimer) {
    clearTimeout(refreshTimer);
    refreshTimer = null;
  }
}

const authService = {
  refreshToken,
  setupRefreshTimer,
  cancelRefreshTimer,
};

export default authService;
