import React, { useState, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Route from 'components/security/route';
import Navbar from 'components/layout/navbar';
import SideNav from 'components/layout/sidenav';

import { routes } from 'routes';

const App = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [search, setSearchFilter] = React.useState('');

  return (
    <>
      <Navbar handleSidenavClick={() => setIsCollapsed(!isCollapsed)} />
      <div className="min-h-screen pt-16">
        <Suspense fallback={<div className="text-center"><CircularProgress disableShrink /></div>}>
          <SideNav isCollapsed={isCollapsed} onSearch={setSearchFilter} />
        </Suspense>
        <Suspense fallback={<div className="text-center"><CircularProgress disableShrink /></div>}>
          <Switch>
            {routes.map((props, index) => <Route exact key={index} {...props} />)}
          </Switch>
        </Suspense>
      </div>
    </>
  )
}

export default App;
