import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

const SideNav = ({ isCollapsed }) => {
  return (
    <div className={`${isCollapsed 
      ? 'translate-x-64 transition duration-500 ease-in-out' 
      : 'translate-x-0 transition duration-500 ease-in-out'} 
      z-50 fixed -left-64 min-h-full text-gray-400 bg-gray-800 w-64`
      }>
      <div className='flex py-2 px-3 hover:bg-cyan-500 hover:text-white justify-between cursor-pointer'>
        <div>info</div>
        <div><FontAwesomeIcon icon={faBuilding} /></div>
      </div>
      <div className='flex py-2 px-3 hover:bg-cyan-500 hover:text-white justify-between cursor-pointer'>
        <div>info</div>
        <div><FontAwesomeIcon icon={faBuilding} /></div>
      </div>
      <div className='flex py-2 px-3 hover:bg-cyan-500 hover:text-white justify-between cursor-pointer'>
        <div>info</div>
        <div><FontAwesomeIcon icon={faBuilding} /></div>
      </div>
      <div className='flex py-2 px-3 hover:bg-cyan-500 hover:text-white justify-between cursor-pointer'>
        <div>info</div>
        <div><FontAwesomeIcon icon={faBuilding} /></div>
      </div>
    </div>
  )
}
export default SideNav;
