import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faUser, faRightFromBracket, faBars } from '@fortawesome/free-solid-svg-icons'

import logo from '../../img/logo.png';
import separator from '../../img/separator.png';

const Navbar = ({ handleSidenavClick }) => {
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  return (
    <div>
      <nav className={`fixed shadow-xl w-full items-center justify-between z-[100]`}>
        <div className={`items-center grow-0 flex flex-shrink-0 bg-gray-800 h-16 overflow-hidden z-50`}>
          <div className='z-50 flex items-center w-full md:w-3/12'>
            <img src={logo} alt="Logo" className='w-20 ml-8 mr-6'/>
            <img src={separator} alt="|" className='ml-6'/>
            <div className='flex mx-3 cursor-pointer' onClick={handleSidenavClick}>
              <div className='text-white mx-1'><FontAwesomeIcon icon={faAngleLeft} /></div>
              <div className='text-white mx-1'><FontAwesomeIcon icon={faBars} /></div>
            </div>
            <img src={separator} alt="|" />
          </div>

          <div className='z-50 text-white w-9/12 text-xs font-bold justify-between hidden md:flex'>
            <div className='flex items-center'>
              <div className='mx-5 cursor-pointer hover:text-cyan-500'>
                <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/main`}>SYSTEMS</a>
              </div>
              <div className='mx-5 cursor-pointer hover:text-cyan-500'>
                <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/notifications`}>NOTIFICATIONS</a>
              </div>
              <div className='mx-5 cursor-pointer hover:text-cyan-500'>
                <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/dashboard/list`}>DASHBOARD</a>
              </div>
              <div className='mx-5 cursor-pointer hover:text-cyan-500'>
                <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/gateways`}>GATEWAYS</a>
              </div>
              <div className='mx-5 cursor-pointer hover:text-cyan-500'>
                <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/users`}>USERS</a>
              </div>
              <div className='mx-5 cursor-pointer hover:text-cyan-500'>
                <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/insights`}>INSIGHTS</a>
              </div>
            </div>
            <div className='flex items-center'>
              <img src={separator} alt="|" />
              <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/users/profile`}>
                <div className='text-white mx-5 cursor-pointer'><FontAwesomeIcon icon={faUser} size="xl"/></div>
              </a>
              <img src={separator} alt="|" />
              <div className='text-white mx-5 cursor-pointer'><FontAwesomeIcon icon={faRightFromBracket} size="xl"/></div>
            </div>
          </div>
          <div className='z-50 flex items-center justify-end w-9/12 md:hidden'>
            <img src={separator} alt="|" />
            <div className='text-white cursor-pointer mx-5'><FontAwesomeIcon icon={faBars} onClick={() => setHamburgerMenu(!hamburgerMenu)} /></div>
          </div>
        </div>
      </nav>
      <div className={`${hamburgerMenu
        ? 'translate-y-96 transition duration-500 ease-in-out'
        : 'translate-y-0 transition duration-500 ease-in-out'}
        z-20 absolute -top-80 w-full text-white text-md font-bold bg-gray-800 pl-8 lg:hidden xl:hidden 2xl:hidden`}
      >
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/main`}>SYSTEMS</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/notifications`}>NOTIFICATIONS</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/dashboard/list`}>DASHBOARD</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/gateways`}>GATEWAYS</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/users`}>USERS</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/insights`}>INSIGHTS</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>
          <a href={`${process.env.REACT_APP_CLOUD_CONNECT_APP}/users/profile`}>PROFILE</a>
        </div>
        <div className='py-2 cursor-pointer hover:text-cyan-500'>LOGOUT</div>
      </div>
    </div>
  )
};

export default Navbar;
