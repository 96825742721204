import axios from 'axios';

import authService from 'utils/auth';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async config => {
    return config;
  },
  async error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.request.responseURL.substr(-13) === '/refreshToken') {
      //handle error when we call the /refreshToken endpoint
    } else {
      if (error.response.status === 401) {
        await authService.refreshToken();
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
