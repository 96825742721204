import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

import './index.css';
import App from './App';
import authService from 'utils/auth';

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { SWRConfig } from 'swr';
import { BrowserRouter } from 'react-router-dom';

moment.locale('en');
const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_CLOUD_CONNECT_REACT_API}/graphql`,
  cache: new InMemoryCache()
});

const token = localStorage.getItem('JWT_TOKEN');

if (token) {
  const decoded = jwtDecode(token);
  const secondsToExpire = decoded.exp - Math.ceil(Date.now() / 1000);

  authService.setupRefreshTimer(secondsToExpire);
} else {
  window.location.href = process.env.REACT_APP_CLOUD_CONNECT_APP;
}

root.render(
  <React.StrictMode>
    <SWRConfig >
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
